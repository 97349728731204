<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      {{ $t('dtouch.customize', locale) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-layout wrap>
        <v-flex xs12 style="padding: 20px 0 0 10px">
          <v-row>
            <v-col>
              <div style="float: left;">
                <label class="dg-label" style="margin-right: 10px;">
                  {{ $t('dtouch.menu', locale) }}
                </label>
              </div>
              <div style="float: left;">
                <color-picker-primary-secondary
                  :primaryColor="editedItem.BGColor"
                  :secondaryColor="editedItem.TextColor"
                  :onChangeColor="onChangeColorMenu"
                  :iconBGAndText="true"
                />
              </div>
            </v-col>
            <v-col>
              <div style="float: left;">
                <label class="dg-label" style="margin-right: 10px;">
                  {{ $t('dtouch.content', locale) }}
                </label>
              </div>
              <div style="float: left;">
                <color-picker-primary-secondary
                  :primaryColor="editedItem.BGColorContent"
                  :secondaryColor="editedItem.TextColorContent"
                  :onChangeColor="onChangeColorContent"
                  :iconBGAndText="true"
                />
              </div>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
  import ColorPickerPrimarySecondary from '@/components/colorPickerPrimarySecondary/Index'
export default {
  name: 'Customize',
  components: {
    ColorPickerPrimarySecondary,
  },
  props: {
    locale: {
      type: String,
      defaul: null
    },
    editedItem: {
      type: Object,
      default: null
    },
    onChangeColorMenu: {
      type: Function,
      required: true,
    },
    onChangeColorContent: {
      type: Function,
      required: true,
    },
  },
}
</script>

