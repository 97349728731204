<template>
  <div>
    <span class="dg-label" v-if="label">
      {{ $t(label, locale).concat(': ') }}
    </span>
    <v-layout wrap class="color-picker-main" align-center>
      <v-flex xs6>
        <color-picker-item
          :left="true"
          :value="selectedPrimaryColor"
          :icon="iconBGAndText ? 'mdi-format-color-fill' : 'mdi-numeric-1-box-outline'"
          :onChange="handleChangePrimaryColor"
        />
      </v-flex>
      <v-flex xs6>
        <color-picker-item
          :left="false"
          :value="selectedSecondaryColor"
          :icon="iconBGAndText ? 'mdi-format-color-text' : 'mdi-numeric-2-box-outline'"
          :onChange="handleChangeSecondaryColor"
        />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import ColorPickerItem from './ColorPickerItem'
  export default {
    name: 'ColorPickerPrimarySecondary',
    components: {
      ColorPickerItem,
    },
    props: {
      label: {
        type: String,
        required: false,
        default: null
      },
      locale: {
        type: String,
        default: null,
      },
      iconBGAndText: {
        type: Boolean,
        required: false,
        default: false
      },
      primaryColor: {
        type: String,
        required: false,
        default: null
      },
      secondaryColor: {
        type: String,
        required: false,
        default: null
      },
      onChangeColor: {
        type: Function,
        required: true
      }
    },
    data: () => ({
      selectedPrimaryColor: null,
      selectedSecondaryColor: null,
    }),
    watch: {
      primaryColor (v) {
        this.selectedPrimaryColor = v
      },
      secondaryColor (v) {
        this.selectedSecondaryColor = v
      },
    },
    mounted () {
      this.selectedPrimaryColor = this.primaryColor
      this.selectedSecondaryColor = this.secondaryColor
    },
    methods: {
      handleChangePrimaryColor (v) {
        this.selectedPrimaryColor = v
        this.onChangeColor(this.selectedPrimaryColor, this.selectedSecondaryColor)
      },
      handleChangeSecondaryColor (v) {
        this.selectedSecondaryColor = v
        this.onChangeColor(this.selectedPrimaryColor, this.selectedSecondaryColor)
      },
    }
  }
</script>
<style scoped>
.color-picker-main {
  width: 100px;
}
</style>

